<template>
  <div class="auth">
    <div class="auth__card card">
      <img class="card__logo" src="@/assets/svg/logo/red.svg" alt=""/>
      <h1 class="card__text">{{ $t('registration') }}</h1>
      <auth-first-step v-if="step === 1" @success="step = 2"/>
      <auth-in-second-step v-if="step === 2" @success="step = 3"/>
      <user-data v-if="step === 3"/>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: 'AuthContent',
  components: {
    AuthFirstStep: () => import('@/components/template/auth/AuthFirstStep'),
    AuthInSecondStep: () => import('@/components/template/auth/AuthInSecondStep'),
    UserData: () => import('@/components/template/auth/UserData')
  },

  data() {
    return {
      step: 1
    }
  },

  methods: {
    ...mapMutations([
      'setAuth',
      'clearAuthForm',
    ]),
  },

  mounted() {
    this.setAuth(false)
  },

  destroyed() {
    this.clearAuthForm()
  }
}


</script>

<style lang="scss" scoped>
.auth {
  min-height: 100vh;
  background: #F5F5F5;
  padding: 150px 20px 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding: 110px 20px 30px;
  }
}

.card {
  max-width: 434px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;
  padding: 30px 34px 40px;

  @media (max-width: 768px) {
    padding: 34px 20px 27px 20px;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
  }
}
::v-deep input {
  font-family: "Gotham Pro Regular";
}
</style>
